import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../config';
import { logInDevelopment } from '../utils'; // Import the utility function

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [userFromApi, setUserFromApi] = useState(null);

  useEffect(() => {
    const loginStatus = Cookies.get('79fefaf2564aabb19d44fe324844c86b');
    
    if (loginStatus === undefined) {
      //window.location.href = 'https://appypie.com/endpoint';
      window.location.href = `${config.MARKETING_URL}`;
    } else if (loginStatus === '1') {
      setIsAuthenticated(true);
      const appyId = Cookies.get('APPYID');
      if (appyId) {
        fetchUserDetails(appyId);
      }
    }
  }, []);

  const fetchUserDetails = async (appyId) => {
    try { console.log('config.API_BASE_URL', config.API_BASE_URL);
      const response = await axios.post(`${config.API_BASE_URL}/common/getuserdetailnew`, {
        file_name: appyId
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Adding logs to track the response
      if (response && response.data) {
        console.log('API Response Data:', response.data);

        if (response.data.userdata) {
          const userDetails = response.data.userdata;
          setUserDetails(userDetails);

          // Extract email, firstName, lastName and fetch user data from another API
          const { email, first_name: firstName, last_name: lastName, c_id: userId } = userDetails;

          logInDevelopment('cognito id=', userId); // Debug log
          if (email) {
            const lName = lastName ? lastName : firstName;
            fetchUserByEmail({ email, firstName, lName, userId });
          }
        } else {
          console.error('No userdata found in the response:', response.data);
          //window.location.href = 'https://appypie.com/endpoint';
          window.location.href = `${config.MARKETING_URL}`;
          
        }
      } else {
        console.error('Invalid API response:', response);
      }
    } catch (error) {
      // Enhanced error handling with proper logging and redirection
      if (error.response) {
        console.error('Error response data:', error.response.data);
        if (error.response.data && error.response.data.message === 'User not found') {
          //window.location.href = 'https://appypie.com/endpoint';
          window.location.href = `${config.MARKETING_URL}`;
        } else {
          console.error('Error response status:', error.response.status);
          console.error('Full error response:', error.response);
        }
      } else if (error.request) {
        console.error('Error request (No response received):', error.request);
      } else {
        console.error('Error message:', error.message);
      }

      // Additional logging for debugging purposes
      if (error.config) {
        console.error('Error config:', error.config);
      }
    }
  };

  const fetchUserByEmail = async ({ email, firstName, lName: lastName, userId }) => {
    try {
      const response = await axios.post(`${config.ENDPOINT_API_URL}/getUser`, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        portal:'snappy'
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setUserFromApi(response.data);
    } catch (error) {
      console.error('Error fetching user by email:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userDetails, userFromApi }}>
      {children}
    </AuthContext.Provider>
  );
};