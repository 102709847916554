const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  ENDPOINT_API_URL: process.env.REACT_APP_ENDPOINT_API_URL,
  SNAPPY_URL: process.env.REACT_APP_SNAPPY_URL,
  MARKETING_URL: process.env.REACT_APP_MARKETING_URL,
  DEVELOPER_PORTAL: process.env.REACT_APP_DEVELOPER_PORTAL
};


//console.log(config);
export default config;
