import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
//import '../../static/css/HomePage.css'; // Import the CSS file for the HomePage component
//import '../../static/css/Style.css';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import config from '../../config';
import { PulseLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import { logInDevelopment } from '../../utils';
import PlanListSnappy from '../../components/snappy/PlanListSnappy';
import ApiInfo from '../../components/home/ApiInfo';
import ProductDropDown from '../../components/snappy/ProductDropDownSnappy';
import ErrorBoundary from '../../components/ErrorBoundary'; // Import ErrorBoundary
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = () => {
    const { isAuthenticated, userDetails, userFromApi } = useContext(AuthContext);
    const [currentUsages, setCurrentLimit] = useState(null);
    const [productData, setProductData] = useState([]);
    const [product, setProduct] = useState(null);
    const [appid, setAppid] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [subscriptionName, setSubscriptionName] = useState('');
    const [subscriptionLimit, setSubscriptionLimit] = useState(0);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [subscriptionPrice, setSubscriptionPrice] = useState(0);
    const [subscriptionPlanId, setSubscriptionPlanId] = useState('');
    const [loading, setLoading] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [subscriptionCreated, setSubscriptionCreated] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading the Plan...');
    const [coupon, setCoupon] = useState('');
    const [apiData, setApiData] = useState(null);
    const [snappyplan, setSnappyplan] = useState(null);
    const mode = process.env.REACT_APP_STAGE || 'development';
    const refreshButtonRef = useRef(null);
    const location = useLocation();
    const ENCRYPTION_KEY = 'HareRamaHareKrishna';

    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(data.toString(), ENCRYPTION_KEY).toString();
    };

    const encryptJSONData = (data) => {
        return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    };

    const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const resetAllStates = () => {
        setCurrentLimit(null);
        setProductData([]);
        setProduct(null);
        setAppid(null);
        setHasSubscription(false);
        setSubscriptionName('');
        setSubscriptionLimit(0);
        setSubscriptionId('');
        setSubscriptionPrice(0);
        setSubscriptionPlanId('');
        setLoading(null);
        setShowLoader(true);
        setRefreshLoading(false);
        setSubscriptionCreated(false);
        setLoadingMessage('Loading the Plan...');
        setCoupon('');
    };

    const initializeProductData = (productParam) => {
        // This function initializes the product data
        if (productParam) {
            setProduct(productParam);
            logInDevelopment('Product parameter from URL (initializeProductData):', productParam);
        }
    };

    const initializeAppidData = (appidParam) => {
        // This function initializes the product data
        if (appidParam) {
            setAppid(appidParam);
            logInDevelopment('Product parameter from URL (initializeAppidData):', appidParam);
        }
    };

    const initializeSnappyplanData = (snappyplanParam) => {
        // This function initializes the product data
        if (snappyplanParam) {
            setSnappyplan(snappyplanParam);
            logInDevelopment('Product parameter from URL (initializeSnappyplanData):', snappyplanParam);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const productParam = queryParams.get('product');
        const appidParam = queryParams.get('appid');
        const snappyplanParam = queryParams.get('snappyplan');
        initializeProductData(productParam);
        initializeAppidData(appidParam);
        initializeSnappyplanData(snappyplanParam);
    }, [location.search]);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                logInDevelopment('Fetching product data for:', product);
               const productResponse = await axios.post(`${config.ENDPOINT_API_URL}/getProduct`, {
                    productName: product,
                    status: 'active',
                    mode: mode
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': '99c4cfca3a2c48409c095fb1b4689f4b'
                    }
                });
                logInDevelopment('Product response:', productResponse.data);
                if (productResponse.data.documents && productResponse.data.documents.length > 0) {
                    //const validProducts = productResponse.data.documents.filter(doc => doc.price >= 0 && doc.planId !== 'free'); // This will remove free plan
                    const validProducts = productResponse.data.documents; // This will show all the plan
                    logInDevelopment("Filtered product details:", validProducts);
                    setProductData(validProducts);
                } else {
                    //console.error('No valid product documents found');
                    toast.success("No valid product were found.");
                    setTimeout(() => {
                        window.location.href = `${config.MARKETING_URL}`;
                      }, 2000);
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        if (product && productData.length === 0) {
            logInDevelopment('Product found in URL:', product);
            fetchProductData();
        } else {
            logInDevelopment('No product found in URL or product data already set');
        }
    }, [product, productData]);

    useEffect(() => {
        const fetchUserProductSubscription = async (userId, productName) => {
            try {
                setLoadingMessage('Loading user...');
                logInDevelopment('Checking user product subscription for:', productName);
                const response = await axios.post(`${config.ENDPOINT_API_URL}/ActiveSubUser`, {
                    userId: userId,
                    productName: productName,
                    status: 'active',
                    appid: appid
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                logInDevelopment('User product subscription response:', response.data);
                if (response.data && response.data.length > 0) {
                    const subscription = response.data[0];
                    setHasSubscription(true);
                    setSubscriptionName(subscription.planName);
                    setSubscriptionLimit(subscription.apiLimit);
                    setSubscriptionId(subscription.subscriptionId);
                    setSubscriptionPrice(subscription.price);
                    setSubscriptionPlanId(subscription.planId);
                    setSubscriptionCreated(true);
                    return subscription.planId;
                } else {
                    logInDevelopment('No active subscription found. Creating a new free subscription.');
                    setLoadingMessage('Creating Subscription...');
                    const subId = 'snappy_' + appid + '_' + userId;
                    const createResponse = await axios.post(`${config.ENDPOINT_API_URL}/azureFreeSub`, {
                        subscriptionId: subId,
                        displayName: 'planId' + appid + '-free',
                        ownerId: userId,
                        scope: productName
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    logInDevelopment('Free subscription creation response:', createResponse.data);
                    if (createResponse.data && createResponse.data.token) {
                        setSubscriptionCreated(true);
                        return await fetchUserProductSubscription(userId, productName);
                    } else {
                        setSubscriptionCreated(false);
                    }
                }
            } catch (error) {
                console.error('Error checking user product subscription:', error);
                setHasSubscription(false);
                setSubscriptionCreated(false);
                return false;
            }
        };

        const fetchUserData = async (productData, planId) => {
            try {
                setLoadingMessage('Loading user...');
                logInDevelopment('Fetching user data for product:', productData[0].productName);
                if (isAuthenticated && userFromApi) {
                    const userId = userFromApi.document.name;
                    logInDevelopment('User ID:', userId);
                    const subscriptionExists = await fetchUserProductSubscription(userId, productData[0].productName);
                    if (subscriptionExists) {
                        logInDevelopment('subscriptionPlanId=', subscriptionPlanId);
                        const response = await axios.post(`${config.ENDPOINT_API_URL}/getdata`, {
                            userId: `/users/${userId}`,
                            productId: `/products/${productData[0].productName}`,
                            planId: 'planId-' + planId || ''
                        });
                        logInDevelopment('User data response:', response.data);
                        setCurrentLimit(response.data.sumCallCountTotal);
                    } else {
                        logInDevelopment('No valid subscription found, skipping getdata call');
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (productData.length > 0 && isAuthenticated && userFromApi) {
            fetchUserProductSubscription(userFromApi.document.name, productData[0].productName).then(planId => {
                fetchUserData(productData, planId);
            });
        }
    }, [isAuthenticated, userFromApi, productData]);

    useEffect(() => {
        const checkSubscriptionCreation = setInterval(() => {
            if (subscriptionPlanId === 'free') {
                const top_menu = document.getElementById('endpoint_top_menu');
                if (top_menu) {
                    top_menu.style.display = 'none';
                }
            }
            if (subscriptionCreated) {
                if (hasSubscription) {
                    setShowLoader(false);
                    clearInterval(checkSubscriptionCreation);
                } else {
                    setTimeout(() => {
                        setShowLoader(false);
                        clearInterval(checkSubscriptionCreation);
                        if (refreshButtonRef.current) {
                            refreshButtonRef.current.click();
                        }
                    }, 2000);
                }
            }
        }, 1000);
        return () => clearInterval(checkSubscriptionCreation);
    }, [subscriptionCreated, hasSubscription, subscriptionPlanId]);

    const handleCancelClick = async (product) => {
        setLoading(product.planId);
        logInDevelopment('subscriptionId :', subscriptionId);
        const subscriptionData = {
            snd: subscriptionId,
            email: userDetails.email
        };
        const encryptedData = encryptJSONData(subscriptionData);
        try {
            const couponResponse = await axios.post(`${config.ENDPOINT_API_URL}/9337223457`, {
                encpayload: encryptedData
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            });
            logInDevelopment('couponResponse after cancel:', couponResponse.data);
            if (couponResponse.data && couponResponse.data.success) {
                resetAllStates();
                const queryParams = new URLSearchParams(location.search);
                const productParam = queryParams.get('product');
                const appidParam = queryParams.get('appid');
                initializeProductData(productParam);
                initializeAppidData(appidParam);
                toast.success("Subscription cancelled");
            } else {
                toast.error("Failed to cancel subscription");
            }
        } catch (error) {
            console.error('Error during subscription cancellation:', error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setLoading(null);
        }
    };

    const handleUpgradeClick = async (product) => {
        setLoading(product.planId);
        const queryParams = new URLSearchParams(location.search);
        const apiParam = queryParams.get('api');
        const operationParam = queryParams.get('operation');
        if (!userFromApi || !userFromApi.document || !userFromApi.document.name) {
            console.error('Error: User information is missing');
            setLoading(null);
            return;
        }
        const userId = userFromApi.document.name;
        const newSubscriptionId = 'snappy_' + appid + '_' + userId;
        const finalSubscriptionId = hasSubscription ? subscriptionId : newSubscriptionId;
        const returnUrl = `api=${apiParam}&operation=${operationParam}`;
        if (product.planId === 'free') {
            try {
                logInDevelopment('User from API:', userFromApi);
                const response = await axios.post(`${config.ENDPOINT_API_URL}/azureFreeSub`, {
                    subscriptionId: finalSubscriptionId,
                    displayName: 'planId' + appid + '-free',
                    ownerId: userId,
                    scope: product.productName
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                logInDevelopment('API response:', response.data);
                if (response.data && response.data.token) {
                    const sharedAccessToken = response.data.token;
                    const redirectUrl = `${config.DEVELOPER_PORTAL}signin-sso?token=${sharedAccessToken}&returnUrl=${encodeURIComponent(returnUrl)}`;
                    window.location.href = redirectUrl;
                } else {
                    console.error('Error: No token received from API');
                }
            } catch (error) {
                console.error('Error calling azureFreeSub API:', error);
            } finally {
                setLoading(null);
            }
        } else {
            try {
                const subscriptionLimitLeftCost = calculateSubscriptionLimitLeftCost();
                logInDevelopment('subscriptionLimitLeftCost:', subscriptionLimitLeftCost);
                if (subscriptionLimitLeftCost > 0) {
                    const encryptedCost = encryptData(subscriptionLimitLeftCost);
                    const couponResponse = await axios.post(`${config.ENDPOINT_API_URL}/728738732873273`, {
                        limitLeft: encryptedCost
                    },{
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    });
                    logInDevelopment('Coupon API response:', couponResponse.data);
                    if (couponResponse.data && couponResponse.data.hash) {
                        logInDevelopment('couponResponse.data.hash:', couponResponse.data.hash);
                        setCoupon(couponResponse.data.hash);
                        logInDevelopment('coupon:', coupon);
                        const url = `${config.SNAPPY_URL}/index/endpoint?p=${product.productName}&pd=${product.planId}&khash=${userId}&sd=${finalSubscriptionId}&${returnUrl}`;
                        window.location.href = `${url}&cp=${couponResponse.data.hash}`;
                    } else {
                        console.error('Error: No hash received from coupon API');
                    }
                } else {
                    setCoupon('');
                    const url = `${config.SNAPPY_URL}/index/endpoint?p=${product.productName}&pd=${product.planId}&khash=${userId}&sd=${finalSubscriptionId}&${returnUrl}`;
                    window.location.href = url;
                }
            } catch (error) {
                console.error('Error calling coupon API:', error);
            } finally {
                setLoading(null);
            }
        }
    };

    const handleRefreshClick = async () => {
        setRefreshLoading(true);
        setCurrentLimit(null);
        try {
            const userId = userFromApi.document.name;
            const response = await axios.post(`${config.ENDPOINT_API_URL}/getdata`, {
                userId: `/users/${userId}`,
                productId: `/products/${product}`,
                planId: `planId-${subscriptionPlanId}`
            });
            logInDevelopment('User data response:', response.data);
            setCurrentLimit(response.data.sumCallCountTotal);
        } catch (error) {
            console.error('Error refreshing user data:', error);
        } finally {
            setRefreshLoading(false);
        }
    };

    const calculateUsagePercentage = () => {
        if (currentUsages !== null && subscriptionLimit !== 0) {
            return (currentUsages / subscriptionLimit) * 100;
        }
        return 0;
    };

    const getFillColor = () => {
        const percentage = calculateUsagePercentage();
        if (percentage <= 70) {
            return '#099eff';
        } else if (percentage > 70 && percentage < 100) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    const calculateSubscriptionLimitLeft = () => {
        return subscriptionLimit - currentUsages;
    };

    const calculateSubscriptionLimitLeftCost = () => {
        const limitLeft = calculateSubscriptionLimitLeft();
        const costPerCall = subscriptionPrice / subscriptionLimit;
        return Math.round(limitLeft * costPerCall);
    };

    const fetchCoupon = async (subscriptionLimitLeftCost) => {
        try {
            const encryptedCost = encryptData(subscriptionLimitLeftCost);
            const response = await axios.post(`${config.ENDPOINT_API_URL}/get42323223`, { limitLeft: encryptedCost });
            if (response.data && response.data.get42323223) {
                const decryptedCouponCode = decryptData(response.data.get42323223);
                setCoupon(decryptedCouponCode);
                logInDevelopment('Coupon Code:', decryptedCouponCode);
            } else if (response.data && response.data.error) {
                logInDevelopment('Error:', response.data.error);
                setCoupon('');
            }
        } catch (error) {
            console.error('Error fetching coupon:', error);
            setCoupon('');
        }
    };

    useEffect(() => {
        if (subscriptionLimit && currentUsages !== null) {
            const subscriptionLimitLeft = calculateSubscriptionLimitLeft();
            const subscriptionLimitLeftCost = calculateSubscriptionLimitLeftCost();
            logInDevelopment('Subscription Limit Left:', subscriptionLimitLeft);
            logInDevelopment('Subscription Limit Left Cost:', subscriptionLimitLeftCost);
            if (subscriptionPlanId !== 'free') {
                // fetchCoupon(subscriptionLimitLeftCost);
            }
        }
    }, [subscriptionLimit, currentUsages, subscriptionPlanId]);

    useEffect(() => {
        if (userFromApi) {
            const userId = userFromApi.document.name;
            if (userId) {
                const sId = 'snappy_' + appid + '_' + userId;
                axios.post(`${config.ENDPOINT_API_URL}/9335347896`, { userId, sId })
                .then(response => {
                // Handle the response
                setApiData(response.data);
                logInDevelopment('apiData=:', response.data);
                })
                .catch(error => {
                // Handle the error
                console.error('Error fetching data from API', error);
                });
            }
        }
      }, [userFromApi]);

  return (
    <div className="main">
    <div className="container">
      {showLoader ? (
        <div className="loader-container">
          <div className="loader-inner">
            <PulseLoader color={"#049EFF"} size={25} margin={4}/>
            <p className="loader-text">{loadingMessage}</p>
          </div>
        </div>
      ): null
      }
          {hasSubscription ? (
            <>
            <div className="plan-header">
              <ProductDropDown 
              product={product} 
              userFromApi={userFromApi} 
              apiData={apiData}
              />
                <div className="item">
                <div className="plan-limit">
                <div className="plan-top">
                <h6>Current limit: <strong>{currentUsages !== null ? `${currentUsages} / ${subscriptionLimit}` : 'Loading...'}</strong></h6>
                <div ref={refreshButtonRef} onClick={handleRefreshClick}>
                        <FontAwesomeIcon
                          icon={faSync}
                          className={`refresh-icon ${refreshLoading ? 'spinning' : ''}`}
                        />
                      </div>
                </div>
                  {subscriptionLimit !== 0 && (
                    <div className="progress-bar">
                      {currentUsages > 0 && (
                        <div className="progress-percentage" style={{ width: `${calculateUsagePercentage()}%`, backgroundColor: getFillColor() }}></div>
                      )}
                    </div>
                  )}
                  </div>
                </div>
            </div>
            
            <ErrorBoundary componentName="ApiInfo">
            <ApiInfo 
            product={product} 
            subscriptionId={subscriptionId} 
            apiData={apiData}
            />
            </ErrorBoundary>
            </>
          ) : (
            <div className="plan-header">
              <div className="item">
              <h3 className="page-title">My Plan</h3>
              <h6 className="page-subtitle">You haven't subscribed for this API</h6>
              </div>
            </div>
          )}
          <ErrorBoundary componentName="PlanListSnappy">
        <PlanListSnappy
        productData={productData}
        subscriptionPlanId={subscriptionPlanId}
        hasSubscription={hasSubscription}
        subscriptionPrice={subscriptionPrice}
        handleUpgradeClick={handleUpgradeClick}
        handleCancelClick={handleCancelClick}
        loading={loading}
        appid={appid}
        snappyplan={snappyplan}
        apiData={apiData}
      />
      </ErrorBoundary>
      {/* Conditionally render mode information */}
      {mode === 'development' && (
        <div className="mode-info">
          <p>Current Mode: <strong>{mode}</strong></p>
        </div>
      )}
      <ToastContainer autoClose={10000} />
    </div>
    </div>
  );  
};

export default HomePage;
